import { useState } from 'react';
//@ts-ignore
// import useGeoLocation from 'react-ipgeolocation';
import axios from 'axios';
import styled from 'styled-components';
import { MediumTokemakDegenesis, DiscordLink } from '../constants/links';
import { IoWarningOutline } from 'react-icons/io5';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';

const SocialLink = styled.span`
  color: #0b61f4;
  text-decoration: underline;
`;

const IAgree = styled.button`
  background-color: #0b61f4;
  border-radius: 5px;
  margin-left: 16px;
  margin-right: 17px;
  padding-top: 9.6px;
  padding-bottom: 8px;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 12px;
  margin-bottom: 20px;
  &:hover {
    background-color: #4c35de;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #777;
  }
`;

interface TermsProps {
  width?: number;
  height?: number;
  onCloseClick?: () => void;
}

export default function Terms({ width, height, onCloseClick }: TermsProps) {
  const [visibleFlag, setVisibleFlag] = useState<boolean>(true);
  const [token, setToken] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');

  // const [userCountry, setUserCountry] = useState<string>('');
  // const [allowed, setAllowed] = useState<boolean>(false);
  // const location = useGeoLocation();

  // useEffect(() => {
  //   if (location.loading) return;
  //   if (!!location.country) {
  //     if (
  //       ['US', 'CA', 'KR', 'CU', 'SY', 'IR', 'SD', 'CN', '3166-2:UA'].includes(
  //         location.country
  //       )
  //     ) {
  //       setUserCountry(location.country);
  //       setAllowed(false);
  //     } else {
  //       setAllowed(true);
  //     }
  //   }
  // }, [location]);

  // function getCountryName(ISO: string) {
  //   //@ts-ignore
  //   const getCountryNames = new Intl.DisplayNames(['en'], { type: 'region' });
  //   return getCountryNames.of(ISO);
  // }

  function setMainStyle() {
    let styles = {
      borderRadius: '5px',
      border: '1px solid #ffffff',
    };
    if (width && height) {
      const firstStyle = {
        width: '' + width + 'px',
        height: '' + height + 'px',
      };
      styles = Object.assign(styles, firstStyle);
    }
    return styles;
  }

  function setLogoContainerStyle() {
    let styles = {
      left: 'calc(50% - 55px)',
      top: '-60px',
      backgroundColor: '#000000',
      color: '#1ed2cf',
    };
    return styles;
  }

  function setTitleStyle() {
    let styles = {
      paddingLeft: '3px',
      marginTop: '60px',
      fontSize: '36px',
      marginBottom: '36.5px',
    };
    return styles;
  }

  function setButtonsStyle() {
    let styles = {
      paddingLeft: '48px',
      paddingRight: '44px',
      height: '54px',
    };
    return styles;
  }

  function setReadStyle() {
    let styles = {
      fontSize: '18.2px',
      letterSpacing: '0.1px',
    };
    return styles;
  }

  function setJoinStyle() {
    let styles = {
      fontSize: '18.2px',
      letterSpacing: '0.2px',
    };
    return styles;
  }

  function setDescriptionStyle() {
    let styles = {
      fontSize: '24px',
      lineHeight: '1.25',
      paddingLeft: '80px',
      paddingRight: '80px',
    };
    return styles;
  }

  function onClose() {
    setVisibleFlag(false);
    if (onCloseClick) onCloseClick();
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <GoogleReCaptcha
        onVerify={(token) => {
          setToken(token);
        }}
      />
      {visibleFlag && (
        <div className="relative flex flex-col" style={setMainStyle()}>
          <div className="absolute" style={setLogoContainerStyle()}>
            <IoWarningOutline size="110px" />
          </div>
          <div className="flex-1 flex flex-col items-center justify-center">
            <div
              className="uppercase text-center font-bold text-white"
              style={setTitleStyle()}
            >
              Terms of Participation
            </div>
            <div
              className="text-white text-center"
              style={setDescriptionStyle()}
            >
              <span>
                Before participating in the DeGenesis, you must affirm that you
                are not located, incorporated, or a citizen or resident of: the
                United States of America (including its territories), Canada,
                Democratic people&apos;s Republic of Korea, Cuba, Syria, Iran,
                Sudan, Republic of crimea, Japan, People&apos;s Republic of
                China, or any state, country, or jurisdiction where it would be
                illegal according to applicable law for participation. If you
                cannot affirm the above, please exit this website.
              </span>
              {/* {allowed ? null : (
                <p className="text-base text-red-400 mt-4">
                  {getCountryName(userCountry)} is restricted.
                </p>
              )} */}
              <div className="text-xl mt-6">
                READ{' '}
                <SocialLink>
                  <a
                    href="./INSUREDAO-DEGENESIS-TERMS-CONDITIONS-20220127.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TERMS
                  </a>
                </SocialLink>
              </div>
            </div>
            {errorMsg ? (
              <p className="text-red-400 text-sm mt-4">{errorMsg}</p>
            ) : null}
            <IAgree
              className="uppercase font-bold cursor-pointer"
              onClick={async (e) => {
                if (process.env.NODE_ENV === 'development') {
                  onClose();
                } else {
                  if (!token) {
                    return;
                  }
                  axios
                    .get(
                      '/api/recaptchaVerify?response=' +
                        encodeURIComponent(token)
                    )
                    .then(async (response: any) => {
                      if (response.data.success) {
                        onClose();
                      } else {
                        setErrorMsg('reCAPTCHA request failure.');
                      }
                    });
                }
              }}
            >
              I Agree
            </IAgree>
          </div>
          <hr />
          <div
            style={setButtonsStyle()}
            className="flex flex-row justify-between text-white uppercase items-center"
          >
            <div style={setJoinStyle()}>
              Join Our{' '}
              <SocialLink>
                <a href={DiscordLink} target="_blank" rel="noreferrer">
                  Discord
                </a>
              </SocialLink>{' '}
              For Updates
            </div>
            <div style={setReadStyle()}>
              Read Our{' '}
              <SocialLink>
                <a
                  href={MediumTokemakDegenesis}
                  target="_blank"
                  rel="noreferrer"
                >
                  Medium Post
                </a>
              </SocialLink>
            </div>
          </div>
        </div>
      )}
    </GoogleReCaptchaProvider>
  );
}
