export const TwitterLink = 'https://twitter.com/insuredao';
export const MediumLink = 'https://medium.com/insuredao';
export const DiscordLink = 'https://discord.com/invite/8BA5f5rurq';
export const TwitterTokenomicsLink =
  'https://twitter.com/TokenReactor/status/1403178251506184195';
export const MediumTokenomicsLink =
  'https://medium.com/tokemak/tokenomics-4b3857badc73';
export const MediumTokemakDegenesis =
  'https://medium.com/insuredao/the-insuredao-degenesis-f6c25d7971c8';
export const TokemakRadio = 'https://www.tokemak.radio/';
export const TokemakXYZ = 'https://insuredao.fi';
export const DiscordIconLink = DiscordLink;
