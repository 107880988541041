const PRICE_MIN = 0.275;
const PRICE_MAX = 0.55;
const TVL_1 = 1650000;
const TVL_2 = 3300000;
const TVL_MAX = 6600000;

export const tvl2price = (tvl: number): number => {
  if (tvl <= TVL_1) {
    return PRICE_MIN;
  } else if (tvl >= TVL_2) {
    return PRICE_MAX;
  } else {
    return PRICE_MIN + (tvl - TVL_1) * (0.275 / 1650000);
  }
};

export const tvl2swapRate = (tvl: number): number => {
  if (tvl <= TVL_2) {
    return 1;
  } else if (tvl >= TVL_MAX) {
    return 0.5;
  } else {
    return TVL_2 / tvl;
  }
};
