import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client';
// import { getMainDefinition } from '@apollo/client/utilities';
// import { WebSocketLink } from '@apollo/client/link/ws';

// const httpLink = new HttpLink({
//   uri: process.env.REACT_APP_THE_GRAPH_LINK,
// });

// const wsLink = new WebSocketLink({
//   uri: process.env.REACT_APP_THE_GRAPH_LINK_WS,
//   options: {
//     reconnect: true,
//   },
// });

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     );
//   },
//   // wsLink,
//   httpLink
// );

export const client = new ApolloClient({
  uri: process.env.REACT_APP_THE_GRAPH_LINK,
  cache: new InMemoryCache(),
});
