import { useState } from 'react';
import styled from 'styled-components';
import { BiRightArrowAlt } from 'react-icons/bi';

import { DiscordLink, MediumTokemakDegenesis } from '../constants/links';
import TokoIcon from '../assets/img/toko.svg';
import TimesThin from '../assets/img/times-thin.svg';

const SocialLink = styled.span`
  color: #0b61f4;
  text-decoration: underline;
`;

interface WelcomeToke2Props {
  width?: number;
  height?: number;
  onCloseClick?: (flag: boolean) => void;
}

export default function WelcomeToke2({
  width,
  height,
  onCloseClick,
}: WelcomeToke2Props) {
  const [visibleFlag, setVisibleFlag] = useState<boolean>(true);

  function setMainStyle() {
    let styles = {
      borderRadius: '5px',
      border: '1px solid #ffffff',
      marginLeft: '15px',
      marginTop: '-7px',
    };
    if (width && height) {
      const firstStyle = {
        width: '' + width + 'px',
        height: '' + height + 'px',
      };
      styles = Object.assign(styles, firstStyle);
    }
    return styles;
  }

  function setLogoContainerStyle() {
    let styles = {
      width: '137px',
      height: '137px',
      left: '-68.5px',
      top: '-70.5px',
      backgroundColor: '#000000',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '16px',
      paddingBottom: '16px',
    };
    return styles;
  }

  function setLogoStyle() {
    let styles = {
      borderRadius: '50%',
      border: '2px solid #0b61f4',
      width: '100%',
      height: '100%',
      paddingTop: '21px',
      paddingLeft: '20px',
    };
    return styles;
  }

  function setTitleStyle() {
    let styles = {
      paddingLeft: '98px',
      paddingTop: '40px',
      fontSize: '36px',
      lineHeight: '1',
    };
    return styles;
  }

  function setDescriptionStyle() {
    let styles = {
      paddingLeft: '48px',
      paddingRight: '48px',
      // marginBottom: '65px',
      fontSize: '24px',
      lineHeight: '1.2',
      paddingTop: '30px',
    };
    return styles;
  }

  function setButtonsStyle() {
    let styles = {
      height: '130px',
    };
    return styles;
  }

  function onClose() {
    setVisibleFlag(false);
    if (onCloseClick) onCloseClick(true);
  }

  function setContinueStyle() {
    let styles = {
      borderBottom: '1px solid #ffffff',
      height: '16px',
      marginRight: '50px',
      fontSize: '24px',
      marginTop: '-25px',
    };
    return styles;
  }

  function setRightStyle() {
    let styles = {
      marginRight: '-6px',
      marginTop: '-2px',
    };
    return styles;
  }

  function setArrowStyle() {
    let styles = {
      marginTop: '-2px',
      marginLeft: '3px',
      marginRight: '20px',
    };
    return styles;
  }

  function setLineStyle() {
    let styles = {
      marginTop: '4px',
      marginLeft: '0px',
    };
    return styles;
  }

  function setLine2Style() {
    let styles = {
      marginTop: '30px',
      marginLeft: '0px',
    };
    return styles;
  }

  function setCloseStyle() {
    let styles = {
      border: '1px solid #ffffff',
      right: '26px',
      top: '23px',
      width: '36px',
      height: '36px',
    };
    return styles;
  }

  function setReadStyle() {
    let styles = {
      fontSize: '18.2px',
      letterSpacing: '0.1px',
      paddingLeft: '51px',
      marginTop: '-22px',
    };
    return styles;
  }

  function setJoinStyle() {
    let styles = {
      fontSize: '18.2px',
      letterSpacing: '0.2px',
      paddingLeft: '51px',
      marginTop: '-22px',
    };
    return styles;
  }

  const texts: string[] = [
    '6,000,000 INSURE are available in the InsureDAO DeGenesis',
    'Everyone will be able to claim INSURE at the same final price between $0.275 and $0.55 per INSURE',
    'Final price will be determined by total commitments by participants, and is capped at $0.55 once total commitments reach $3.3M',
    'If total commitments surpass $3.3M (up to the max cap of $6.6M), a proportional swap/the special farming split is globally applied',
    'Everyone’s commitments qualify for the same relative split between INSURE swap and a period of the special farming in our InsureDAO Reserve Pool Individual ETH addresses have max cap of $100,000 in USDC, (1USDC is regarded as $1)',
    'The InsureDAO DeGenesis will have a fourteen-day commitment period during 31st January - 13th February. There is no grace period.',
    'Anyone can participate in the InsureDAO DeGenesis. There is an additional bonus to specific DeFi users for 10% of the amount each participant purchased.',
  ];

  return (
    <div>
      {visibleFlag && (
        <div className="relative flex flex-col" style={setMainStyle()}>
          <div className="absolute px-3 py-3" style={setLogoContainerStyle()}>
            <div className="px-3 py-3 flex" style={setLogoStyle()}>
              <img src={TokoIcon} width="62px" alt="Token Icon" />
            </div>
          </div>
          <div
            className="absolute text-white cursor-pointer"
            style={setCloseStyle()}
            onClick={(e) => onClose()}
          >
            <img src={TimesThin} alt="" />
          </div>
          <div className="flex-1 flex flex-col">
            <div
              className="uppercase text-2xl text-left font-bold"
              style={setTitleStyle()}
            >
              <span className="text-white">The Degenesis </span>
              <span className="text-green">Tl;dr</span>
            </div>
            <div
              className="text-white text-md text-left"
              style={setDescriptionStyle()}
            >
              {texts.map((text: any, i: number) => (
                <div
                  className="flex flex-row text-xl"
                  key={`welcome-text-${i}`}
                  style={setLineStyle()}
                >
                  <BiRightArrowAlt
                    className="align-middle text-green flex-shrink-0"
                    size={24}
                    style={setArrowStyle()}
                  />
                  {text}
                </div>
              ))}
            </div>
          </div>
          <hr />
          <div
            style={setButtonsStyle()}
            className="flex flex-row items-center justify-between text-white"
          >
            {/* <div className="flex-1 flex flex-row" style={setSocialStyle()}> */}
            {/* <SocialButton
                width={48}
                height={48}
                socialsize={30}
                socialtype="twitter"
                fontsize={24}
                description={'Twitter Post'}
                url={TwitterTokenomicsLink}
              />
              <div style={setSocialBetweenStyle()}></div>
              <SocialButton
                width={48}
                height={48}
                socialsize={30}
                socialtype="medium"
                fontsize={24}
                description={'Medium Article'}
                className={'ml-2'}
                url={MediumTokenomicsLink}
              /> */}
            <div style={setJoinStyle()}>
              JOIN OUR{' '}
              <a href={DiscordLink} target="_blank" rel="noreferrer">
                <SocialLink>DISCORD</SocialLink>
              </a>{' '}
              FOR UPDATES
            </div>
            <div style={setReadStyle()}>
              READ OUR{' '}
              <a href={MediumTokemakDegenesis} target="_blank" rel="noreferrer">
                <SocialLink>MEDIUM POST</SocialLink>
              </a>
            </div>
            {/* </div> */}
            <div
              className="text-white flex flex-row items-center cursor-pointer"
              style={setContinueStyle()}
              onClick={(e) => onClose()}
            >
              continue to site
              <BiRightArrowAlt
                className="align-middle"
                size={28}
                style={setRightStyle()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
